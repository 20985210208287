
import { Vue, Options } from 'vue-class-component';
import {
  Ethnicity,
  ExternalPatientReference,
  ExternalPatientReferenceType,
  PaginatedResponse,
  Patient,
  MPIPatient,
  ExternalPatientSearchParam,
  ExternalPatientSearchResult
} from '@/models';
import {
  EthnicityService,
  ExternalPatientLookupService,
  OrganisationPatientService,
  OrganisationExternalPatientService
} from '@/services/api';
import axios, { AxiosRequestConfig, CancelToken, CancelTokenSource } from 'axios';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import { AccountList, ActionModal, BaseButton, PatientResults, PatientSearchCard } from '@/lib/components';

import { IAccount, IModalAction } from '@/lib';
import { getDobFromISOString, verifyMrn } from '@/helpers/patient.helper';
import { formattedNHSNumber } from '@/lib/helpers/nhs-number.helper';
import dayjs from 'dayjs';
import ExternalPatientLookupSelectedPatientDetails
  from '@/views/patient/components/ExternalPatientLookupSelectedPatientDetails.vue';
import ExternalPatientLookupMrnModal from '@/views/patient/components/ExternalPatientLookupMrnModal.vue';
import ExternalPatientLookupPrimaryId from '@/views/patient/components/ExternalPatientLookupPrimaryId.vue';
import ExternalPatientLookupPatientDetailModal
  from '@/views/patient/components/ExternalPatientLookupPatientDetailModal.vue';
import { genderOptions } from '@/constants';
import { Pathways, recordUserEvent } from '@/helpers/aws.helper';
import { useHistoryStore } from '@/stores/history.store';
import { useSessionStore } from '@/stores/session.store';
import { usePatientStore } from '@/stores/patient.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  components: {
    ExternalPatientLookupPatientDetailModal,
    ExternalPatientLookupPrimaryId,
    ExternalPatientLookupMrnModal,
    ExternalPatientLookupSelectedPatientDetails,
    PatientResults,
    AccountList,
    BaseButton,
    PatientSearchCard,
    WorkflowLayout,
    ActionModal
  }
})
export default class ExternalPatientLookUpPage extends Vue {
  loading = false;
  saving = false;
  showMRNAccountModal = false;
  showPatientDetailModal = false;
  showPatientResult = false;
  ethnicities: Array<Ethnicity> = [];
  selectedViewPatient: MPIPatient | null = null;
  selectedPatient: MPIPatient | null = null;
  mrn: IAccount | null = null;
  errors: { [key: string]: string[] } = {};
  editMrnValue = '';

  searchResultOrganisationId = '';
  searchResultPatientMrn = '';
  patientIdAtExternalOrganisation = '';
  patientMrnAtExternalOrganisation = '';
  patientIdAtCurrentOrganisation = '';
  isPatientLinkedToCurrentOrganisation = false;

  createOrUpdateFail = false;

  ethnicityService = new EthnicityService();
  historyStore = useHistoryStore();
  sessionStore = useSessionStore();
  patientStore = usePatientStore();
  notificationStore = useNotificationStore();
  request: CancelTokenSource | null = null;

  searchTypeIndex = 0;
  searchResult: Array<ExternalPatientSearchResult> = [];

  searchParams: ExternalPatientSearchParam = {
    GUID: '',
    type: 'MPI',
    nhsNumber: '',
    dob: '',
    givenName: '',
    familyName: '',
    gender: '',
    postcode: ''
  };

  areMinimumSearchRequirementsMet = false;

  showPatientResultsTable = true;

  PATIENT_SEARCH_STEP = 0;
  PATIENT_DETAIL_STEP = 1;

  isPatientUpdate = false;

  get orgPatientService(): OrganisationPatientService {
    return new OrganisationPatientService(this.orgId);
  }

  get organisationExternalPatientService(): OrganisationExternalPatientService {
    return new OrganisationExternalPatientService(this.orgId);
  }

  get externalPatientLookupService(): ExternalPatientLookupService {
    return new ExternalPatientLookupService(this.orgId);
  }

  get steps() {
    return [this.$t('custom.uhb.patient.search'), this.$t('platform.patient.detail')];
  }

  get modalActions(): IModalAction[] {
    return [
      {
        color: 'primary',
        label: this.$t('platform.patient.view'),
        onClick: () => this.$router.push({
          name: 'patient',
          params: {
            organisationId: this.orgId,
            patientId: this.patientIdAtCurrentOrganisation
          }
        })
      },
      {
        color: 'secondary',
        label: this.$t('platform.patient.add-another-patient'),
        onClick: () => this.$router.push({ name: 'patient-search', params: { organisationId: this.orgId } })
      },
      {
        color: 'ghost',
        label: this.$t('platform.patient.return-to-patients'),
        onClick: () => this.$router.push({ name: 'patient-list', params: { organisationId: this.orgId } })
      }
    ];
  }

  beforeCreate() {
    this.searchResult = [
      {
        label: this.$t('custom.uhb.patient.mpi-patient-title') as string,
        type: 'MPI',
        rows: []
      },
      {
        label: this.$t('custom.uhb.patient.pds-patient-title') as string,
        type: 'SPINE',
        rows: []
      }
    ];
  }

  async mounted() {
    // Make sure we always start on the first step when navigating to this page
    if (this.step > 0) {
      await this.$router.replace(this.$route.path);
    }

    recordUserEvent('started searching to add a new patient', Pathways.PLATFORM);

    this.request = axios.CancelToken.source();
    this.ethnicities = await this.fetchEthnicities(this.request.token);
    this.request = null;
  }

  unmounted() {
    if (this.request) {
      this.request.cancel();
    }
  }

  get currentTypeForAlert() {
    const typesForAlert: { [key: string]: string } = {
      MPI: 'MPI',
      SPINE: 'PDS'
    };
    return typesForAlert[this.searchResult[this.searchTypeIndex].type];
  }

  get patientDetails() {
    if (this.selectedViewPatient) {
      return [
        {
          label: this.$t('custom.uhb.patient.nhs-number'),
          value: this.selectedViewPatient.nhs_number
        },
        {
          label: this.$t('platform.patient.date-of-birth'),
          value: this.selectedViewPatient.date_of_birth
            ? this.$d(getDobFromISOString(this.selectedViewPatient.date_of_birth), 'short')
            : ''
        },
        {
          label: this.$t('platform.patient.given-names'),
          value: this.selectedViewPatient.first_name
        },
        {
          label: this.$t('platform.patient.family-name'),
          value: this.selectedViewPatient.last_name
        },
        {
          label: this.$t('platform.patient.gender'),
          value: this.getGender(this.selectedViewPatient.gender)
        },
        {
          label: this.$t('platform.patient.ethnicity'),
          value: this.selectedViewPatient.ethnicity ? this.selectedViewPatient.ethnicity.title : ''
        },
        {
          label: this.$t('platform.patient.email-address'),
          value: this.selectedViewPatient.email
        },
        {
          label: this.$t('platform.patient.contact-number'),
          value: this.selectedViewPatient.contact_number
        },
        {
          label: this.$t('platform.patient.address'),
          value: this.selectedViewPatient.fhir_address ? this.selectedViewPatient.fhir_address.text : ''
        }
      ];
    }
    return null;
  }

  get patientEthnicityTitle(): string {
    return this.selectedPatient?.ethnicity?.title ?? '';
  }

  get step(): number {
    return Number(this.$route.query.step || 1) - 1;
  }

  get orgId(): string {
    return this.$route.params.organisationId;
  }

  get primaryExternalPatientReferenceType(): ExternalPatientReferenceType {
    return {
      id: 'mrn',
      key: 'mrn',
      label: this.$t('platform.patient.mrn') as string
    };
  }

  get account() {
    if (this.selectedPatient && this.selectedPatient.external_patient_references) {
      return this.selectedPatient.external_patient_references.map((item) => ({
        value: item.value,
        label: 'uhb-nhs-number'
      }));
    }
    return [];
  }

  get readyToComplete(): boolean {
    if (this.isHospitalSearch) {
      return !!this.selectedPatient && !this.saving && this.doesMrnMatch;
    }
    return !!this.selectedPatient && !this.saving && this.doesMrnMatch && !!this.mrn && !!this.mrn.value;
  }

  get isPDSSearch(): boolean {
    return this.searchResult[this.searchTypeIndex].type === 'SPINE';
  }

  get isPatientIdInExternalOrganisation(): boolean {
    return Boolean(this.patientIdAtExternalOrganisation.length);
  }

  get isPatientIdInCurrentOrganisation(): boolean {
    return Boolean(this.patientIdAtCurrentOrganisation.length);
  }

  get isHospitalSearch(): boolean {
    if (this.selectedPatient) {
      return this.selectedPatient.organisational_unit_id === this.orgId;
    }
    return false;
  }

  get showAddMrnButton(): boolean {
    return !this.isHospitalSearch && this.doesMrnMatch;
  }

  get showEditAndDeleteMrnButtons(): boolean {
    return !this.isHospitalSearch && this.doesMrnMatch;
  }

  get showNotApplicableMRN(): boolean {
    return !this.mrn && this.isPDSSearch && this.isHospitalSearch;
  }

  get doesMrnMatch(): boolean {
    return (
      !this.isPatientIdInExternalOrganisation || this.searchResultPatientMrn === this.patientMrnAtExternalOrganisation
    );
  }

  getAge(dob: string) {
    return dayjs().diff(dayjs(dob), 'year');
  }

  getNhsNumber(externalPatientReferences: Array<ExternalPatientReference>) {
    const nhsNumberType = this.patientStore.externalPatientReferenceTypes.find(
      (item: ExternalPatientReferenceType) => item.key === 'UHB_NHS_NUMBER_KEY'
    );

    if (nhsNumberType) {
      const NhsNumber = externalPatientReferences.find(
        (reference: ExternalPatientReference) => reference.external_patient_reference_type_id === nhsNumberType.id
      );
      return NhsNumber && NhsNumber.value ? formattedNHSNumber(NhsNumber.value) : '';
    }
    return '';
  }

  getGender(gender: string) {
    return genderOptions().find((option) => option.value === gender)?.label;
  }

  next() {
    // @ts-ignore
    this.$router.push({
      ...this.$route,
      query: {
        ...this.$route.query,
        step: String(this.step + 2) // Add 2 because the URL param is 1-based
      }
    });
  }

  back() {
    this.mrn = null;
    this.patientIdAtCurrentOrganisation = '';
    this.patientIdAtExternalOrganisation = '';
    if (this.step === 0) {
      this.cancel();
      return;
    }

    // @ts-ignore
    this.$router.push({
      ...this.$route,
      query: {
        ...this.$route.query,
        step: String(this.step) // Add 2 because the URL param is 1-based
      }
    });
  }

  viewPatient(patient: MPIPatient) {
    this.showPatientDetailModal = true;
    this.selectedViewPatient = patient;
  }

  closePatientDetailModal() {
    this.showPatientDetailModal = false;
    this.selectedViewPatient = null;
  }

  editMrn(account: IAccount) {
    this.editMrnValue = account.value ? account.value : '';
    this.showMRNAccountModal = true;
  }

  deleteMrn() {
    if (this.mrn && this.selectedPatient) {
      this.mrn = { ...this.mrn, value: '' };
      this.selectedPatient = { ...this.selectedPatient, mrn: '' };
    }
  }

  async saveMrn() {
    this.clearAccountError();
    if (this.selectedPatient) {
      try {
        const updatedMrn: IAccount = {
          label: this.primaryExternalPatientReferenceType.label,
          typeId: this.primaryExternalPatientReferenceType.id,
          value: this.editMrnValue
        };
        await this.validateMrn(updatedMrn.value ? updatedMrn.value : '');
        this.mrn = updatedMrn;
        this.selectedPatient.mrn = this.editMrnValue;
        this.closeAccountModal();
      } catch (error) {
        this.errors = {
          ...this.errors,
          account: [error.message]
        };
      }
    }
  }

  async checkPatientExist() {
    this.request = axios.CancelToken.source();
    const params = {
      ...(!this.isPDSSearch && this.selectedPatient
        ? { 'filter[mrn]': this.selectedPatient.mrn }
        : this.isPDSSearch && this.selectedPatient && this.selectedPatient.nhs_number
          ? {
            'filter[nhsNumber]': this.selectedPatient.nhs_number.replace(/ /g, '')
          }
          : { 'filter[nhsNumber]': 'Unknown nhs_number' }),
      organisational_unit_id: this.searchResultOrganisationId,
      include: 'patient',
      'filter[trashed]': 'with'
    };

    const requestConfig: AxiosRequestConfig = {
      params,
      cancelToken: this.request.token
    };
    // Search patient with the same mrn at external source.
    const isPatientInSearchResultResponse = await new OrganisationExternalPatientService(
      this.searchResultOrganisationId
    ).search(requestConfig);

    if (
      isPatientInSearchResultResponse.length &&
      isPatientInSearchResultResponse[0] &&
      isPatientInSearchResultResponse[0].id
    ) {
      this.isPatientUpdate = true;
      this.patientIdAtExternalOrganisation = isPatientInSearchResultResponse[0].id;
      this.patientMrnAtExternalOrganisation = isPatientInSearchResultResponse[0].mrn;

      try {
        const response = await this.orgPatientService.fetch(this.patientIdAtExternalOrganisation);
        this.patientIdAtCurrentOrganisation = response.id;
        if (response.mrn) {
          this.isPatientLinkedToCurrentOrganisation = true;
          this.mrn = {
            label: this.primaryExternalPatientReferenceType.label,
            typeId: this.primaryExternalPatientReferenceType.id,
            value: response.mrn
          };
        }
      } catch (error) {
        this.patientIdAtCurrentOrganisation = '';
        this.isPatientLinkedToCurrentOrganisation = false;
      }
    }
  }

  async validateMrn(mrn: string) {
    if (!verifyMrn(mrn)) {
      throw new Error(this.$t('custom.uhb.consult.spaces-not-allowed') as string);
    }
    this.request = axios.CancelToken.source();
    const requestConfig: AxiosRequestConfig = {
      params: {
        include: 'patient',
        'filter[mrn]': mrn,
        'filter[trashed]': 'with'
      },
      cancelToken: this.request.token
    };
    const response = (await this.orgPatientService.index(requestConfig)) as PaginatedResponse<Patient[]>;
    if (response.data.length) {
      throw new Error(this.$t('platform.patient.mrn-error') as string);
    }
  }

  clearAccountError() {
    if (this.errors.account && this.errors.account.length) {
      this.errors = {
        ...this.errors,
        account: []
      };
    }
    if (this.errors.mrn && this.errors.mrn.length) {
      this.errors = {
        ...this.errors,
        mrn: []
      };
    }
  }

  closeAccountModal() {
    this.showMRNAccountModal = false;
    this.editMrnValue = '';
  }

  async search(params: ExternalPatientSearchParam) {
    // We need this next tick to check for minimum requirements before doing a search.
    await this.$nextTick();
    if (!this.areMinimumSearchRequirementsMet) {
      this.showPatientResultsTable = false;
      this.clearSearchResults();
      return;
    }
    this.showPatientResultsTable = true;
    this.loading = true;

    try {
      const requestConfig: AxiosRequestConfig = {
        params
      };
      const response = await this.externalPatientLookupService.search(requestConfig);
      this.searchResultOrganisationId = response.organisational_unit_id;
      this.searchResult[this.searchTypeIndex].rows = response.data.map((patient: MPIPatient) => {
        patient.nhs_number = patient.external_patient_references
          ? this.getNhsNumber(patient.external_patient_references)
          : '';
        patient.age = patient.date_of_birth ? this.getAge(patient.date_of_birth) : 0;

        return patient;
      });
      this.showPatientResult = true;
    } catch (error) {
      if (error.response.status === 500) {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.patient.searching-patients'),
          label: this.$t('custom.uhb.patient.external-lookup-general-error')
        });
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.patient.searching-patients'),
          label: error.response.data.message
        });
      }
    } finally {
      this.loading = false;
    }
  }

  async fetchEthnicities(cancelToken: CancelToken) {
    return (
      await this.ethnicityService.index({
        cancelToken
      })
    ).data;
  }

  async selectPatient(patient: MPIPatient) {
    this.selectedPatient = { ...patient };

    this.searchResultPatientMrn = patient.mrn;
    await this.checkPatientExist();

    // if both have an MRN, keep the existing one!
    if (this.isHospitalSearch && this.selectedPatient.mrn) {
      this.mrn = {
        label: this.primaryExternalPatientReferenceType.label,
        typeId: this.primaryExternalPatientReferenceType.id,
        value: this.selectedPatient.mrn
      };
    }

    this.clearAccountError();
    if (!this.doesMrnMatch) {
      this.errors = {
        ...this.errors,
        mrn: [this.$t('custom.uhb.patient.external-patient-exist-in-hospital-with-different-mrn') as string]
      };
    }

    this.next();
  }

  // Update patient
  async updateExistingPatient(patientId: string): Promise<MPIPatient | undefined> {
    try {
      if (this.selectedPatient) {
        if (this.selectedPatient.ethnicity) {
          this.selectedPatient.ethnicity_id = this.selectedPatient.ethnicity.id;
        }
        return await this.organisationExternalPatientService.update(patientId, this.selectedPatient);
      }
      return;
    } catch (error) {
      if (error.response.status === 422) {
        this.errors = error.response.data.errors;
        this.saving = false;
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('platform.patient.saving-error'),
          label: error.response.data.message
        });
      }
      return;
    }
  }

  async completeCreateNewPatientAtCurrentOrganisationAndAttachToSearchResult() {
    if (this.selectedPatient) {
      const createPatientAtCurrentOrganisationResponse = await this.createPatientAtCurrentOrganisation();
      if (createPatientAtCurrentOrganisationResponse && createPatientAtCurrentOrganisationResponse.id) {
        this.patientIdAtCurrentOrganisation = createPatientAtCurrentOrganisationResponse.id;
        await this.attachPatientAtSearchResult(this.patientIdAtCurrentOrganisation);
      }
    }
  }

  async completeLinkPatientToCurrentOrganisationAndUpdatePatientInformation() {
    const response = await this.attachPatientToCurrentOrganisation(this.patientIdAtExternalOrganisation);
    if (response && response.id) {
      const updateResponse = await this.updateExistingPatient(response.id);
      if (updateResponse && updateResponse.id) {
        this.patientIdAtCurrentOrganisation = updateResponse.id;
      }
    }
  }

  async completeLinkPatientToSearchResultAndUpdatePatientInformation() {
    const response = await this.attachPatientAtSearchResult(this.patientIdAtCurrentOrganisation);
    if (response && response.id) {
      await this.updateExistingPatient(response.id);
    }
  }

  async complete() {
    this.saving = false;
    this.createOrUpdateFail = false;

    // Search-result patient is in the current user's organisation, and we found the patient
    if (this.isHospitalSearch && this.patientIdAtCurrentOrganisation) {
      await this.updateExistingPatient(this.patientIdAtCurrentOrganisation);
    } else if (this.isHospitalSearch && !this.patientIdAtCurrentOrganisation) {
      // Search-result patient is in the current user's organisation, but we cannot find the patient
      const createdPatientAtCurrentOrganisation = await this.createPatientAtCurrentOrganisation();
      if (createdPatientAtCurrentOrganisation && createdPatientAtCurrentOrganisation.id) {
        this.patientIdAtCurrentOrganisation = createdPatientAtCurrentOrganisation.id;
      }

      recordUserEvent(
        'completed searching and added a new patient',
        Pathways.PLATFORM,
        this.patientIdAtCurrentOrganisation
      );
    } else if (!this.isPatientIdInExternalOrganisation && !this.isPatientIdInCurrentOrganisation) {
      // Neither search result nor current organisation have that patient: Create new patient at current organisation and attach to search result
      await this.completeCreateNewPatientAtCurrentOrganisationAndAttachToSearchResult();

      recordUserEvent(
        'completed searching and added a new patient',
        Pathways.PLATFORM,
        this.patientIdAtCurrentOrganisation
      );
    } else if (
      this.isPatientIdInExternalOrganisation &&
      !this.isPatientIdInCurrentOrganisation &&
      this.selectedPatient
    ) {
      // Search result has that patient: Link patient to current organisation, and update patient info.
      await this.completeLinkPatientToCurrentOrganisationAndUpdatePatientInformation();
    } else if (
      !this.isPatientIdInExternalOrganisation &&
      this.isPatientIdInCurrentOrganisation &&
      this.selectedPatient
    ) {
      // Current organisation has that patient: Link patient to search result, and update patient info.
      await this.completeLinkPatientToSearchResultAndUpdatePatientInformation();
    } else if (
      this.isPatientIdInExternalOrganisation &&
      this.isPatientIdInCurrentOrganisation &&
      this.selectedPatient
    ) {
      // Current organisation and search result both have the patient: Update the patient info.
      await this.updateExistingPatient(this.patientIdAtCurrentOrganisation);
    }

    this.saving = false;
    this.next();
  }

  async createPatientAtCurrentOrganisation(): Promise<MPIPatient | undefined> {
    try {
      if (this.selectedPatient) {
        if (this.selectedPatient.ethnicity) {
          this.selectedPatient.ethnicity_id = this.selectedPatient.ethnicity.id;
        }
        return (await this.organisationExternalPatientService.create(this.selectedPatient)).data;
      }
      return;
    } catch (error) {
      this.createOrUpdateFail = true;
      if (error.response.status === 422) {
        this.errors = error.response.data.errors;
        this.saving = false;
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('platform.patient.saving-error'),
          label: error.response.data.message
        });
      }
      return;
    }
  }

  async attachPatientAtSearchResult(patientId: string): Promise<MPIPatient | undefined> {
    try {
      const response = await new OrganisationExternalPatientService(this.searchResultOrganisationId).attach(patientId, {
        organisational_unit_id: this.searchResultOrganisationId,
        mrn: this.searchResultPatientMrn
      });
      return response.data;
    } catch (error) {
      this.createOrUpdateFail = true;
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.patient.saving-error'),
        label: this.$t('custom.uhb.patient.external-patient-exist-in-hospital-with-different-mrn')
      });
      return;
    }
  }

  async attachPatientToCurrentOrganisation(patientId: string): Promise<MPIPatient | undefined> {
    try {
      const response = await this.organisationExternalPatientService.attach(patientId, {
        organisational_unit_id: this.searchResultOrganisationId,
        mrn: this.selectedPatient && this.selectedPatient.mrn ? this.selectedPatient.mrn : ''
      });
      return response.data;
    } catch (error) {
      this.createOrUpdateFail = true;
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.patient.saving-error'),
        label: this.$t('custom.uhb.patient.external-patient-exist-in-hospital-with-different-mrn')
      });
      return;
    }
  }

  cancel() {
    this.$router.push({
      name: 'patient-list',
      params: { organisationId: this.orgId }
    });
  }

  updateSearchType(index: number) {
    this.searchTypeIndex = index;
  }

  clearSearchResults() {
    this.searchResult = [
      {
        label: this.$t('custom.uhb.patient.mpi-patient-title') as string,
        type: 'MPI',
        rows: []
      },
      {
        label: this.$t('custom.uhb.patient.pds-patient-title') as string,
        type: 'SPINE',
        rows: []
      }
    ];
  }
}
